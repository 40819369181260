import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import UserInfo from "../components/UserInfo/UserInfo";
import Disqus from "../components/Disqus/Disqus";
import PostTags from "../components/PostTags/PostTags";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import SEO from "../components/SEO/SEO";
import Footer from "../components/Footer/Footer";
import Content from "../components/Content/Content";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.css";

import FullWidthImage2 from "../components/FullWidthImage2/FullWidthImage2";
// import img from "../../imgs/karsten-wurth-inf1783-55271-unsplash-cropped.jpg";
import img from "../imgs/american-public-power-association-419672-unsplash.jpg";

export default class ProjectPostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    let design = null;
    if (post.design) {
      design = new Date(post.design);
    }
    let inservice = null;
    if (post.inservice) {
      inservice = new Date(post.inservice);
    }

    return (
      <Layout location={this.props.location}>
        <div className="about-container underNavbar">
          <Helmet htmlAttributes={{ lang: "en" }}>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <FullWidthImage2 title="Projects" image={img} />
          <Content style={{ paddingTop: 35 }}>
            <center>
              {/* <h1 style={{ color: "black" }}>{post.title}</h1> */}
              <div className="columns">
                <div
                  className="column is-three-fifths"
                  style={
                    {
                      /* width: "60%" */
                    }
                  }
                >
                  <img
                    src={`${post.thumbnail.startsWith("/") ? "" : "/"}${post.thumbnail}`}
                    alt="Featured"
                    style={{
                      maxHeight: 400,
                      // maxWidth: 900,
                      height: "auto",
                      width: "auto"
                    }}
                  />
                </div>
                {/* <div
                  style={
                    {
                      width: "40%"
                    }
                  }
                > */}
                <div
                  className="column is-two-fifths"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div>
                    <h1 style={{ color: "black" }}>{post.title}</h1>
                  </div>
                  <div
                    style={{
                      alignItems: "start",
                      display: "flex",
                      flexDirection: "column",
                      padding: 10,
                      border: "1px solid #ddd",
                      backgroundColor: "#fafafa"
                    }}
                  >
                    {post.location && (
                      <p>
                        <strong>Location: </strong>
                        {post.location}
                      </p>
                    )}
                    {post.wattage && (
                      <p>
                        <strong>Wattage: </strong>
                        {post.wattage}
                      </p>
                    )}
                    {post.client && (
                      <p>
                        <strong>Client: </strong>
                        {post.client}
                      </p>
                    )}
                    {design && (
                      <p>
                        <strong>Design: </strong>
                        {`${monthNames[design.getMonth()]} ${design.getFullYear()}`}
                      </p>
                    )}
                    {inservice && (
                      <p>
                        <strong>Inservice: </strong>
                        {`${monthNames[inservice.getMonth()]} ${inservice.getFullYear()}`}
                      </p>
                    )}
                    {/* </div> */}
                  </div>
                  {post.project_pdf && (
                    <div
                      style={{
                        // alignItems: "start",
                        // justifyContent: "start",
                        paddingTop: 15,
                        display: "flex"
                      }}
                    >
                      <a
                        href={
                          require(`../../static${post.project_pdf.startsWith("/") ? "" : "/"}${
                            post.project_pdf
                          }`).default
                        }
                        download
                      >
                        Download the project PDF <i className="fa fa-file-pdf" />
                      </a>
                      {/* <h3
                      style={{
                        color: "black",
                      }}
                    >
                      Documents
                    </h3> */}
                    </div>
                  )}
                </div>
              </div>
            </center>
          </Content>

          <Content style={{ padding: 110, paddingBottom: 50, paddingTop: 10 }}>
            <h2>Project Description</h2>
            <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            {/* <div className="post-meta">
              <PostTags tags={post.tags} />
              <SocialLinks postPath={slug} postNode={postNode} />
            </div> */}
            {/* <UserInfo config={config} />
            <Disqus postNode={postNode} />
            <Footer config={config} /> */}
          </Content>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const projectPageQuery = graphql`
  query ProjectPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        # cover
        date
        # category
        # tags
        location
        thumbnail
        client
        design
        inservice
        wattage
        project_pdf
      }
      fields {
        slug
        date
      }
    }
  }
`;
